import React from 'react';

import * as Colors from '@brightlayer-ui/colors';
import { setPayload } from '@fiji/common/src/features/widgetManagement/widgetSlice';
import { WidgetsColumnSkeleton } from './Skeletons';
import { useGetAllWidgetsQuery } from '@fiji/common/src/features/widgetManagement/widgetApi';
import { useAppDispatch, useTypedSelector } from '@fiji/common/src/app/store';
import ArrowDownward from '@mui/icons-material/ArrowDownward';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import { IconButton, List, ListItem, ListItemIcon, ListItemText, Radio, Stack, Typography } from '@mui/material';
import { useDebounce, useIsMount } from '@fiji/common/src/hooks';
import { CustomTransComponent } from 'components';
import { CustomEmptyState } from 'components/CustomEmptyState';
import { ArrowUpward } from '@mui/icons-material';
import { getWidgetTypeValidationConfig } from '@fiji/common/src/utils/helpers';

export const WidgetsColumn = (): JSX.Element => {
    const dispatch = useAppDispatch();

    const payload = useTypedSelector((state) => state.widget.payload);
    const searchKey = useTypedSelector((state) => state.widget.searchKey);
    const [sortPayload, setSortPayload] = React.useState({ key: 'name', sortType: 'DESC' });

    const [, debouncedValue] = useDebounce(undefined, undefined, searchKey);

    const isMount = useIsMount();

    const {
        data: widgets,
        isFetching,
        isLoading,
    }: any = useGetAllWidgetsQuery(
        {
            body: {
                page: 0,
                size: 100,
                filters: { widgetType: [payload?.widgetType?.id] },
                ...(!isMount && debouncedValue && { searchKey: debouncedValue }),
                sort: sortPayload,
            },
        },

        { skip: !payload?.widgetType }
    );
    return (
        <Stack>
            <List disablePadding className="bg-white">
                {isLoading || isFetching ? (
                    <WidgetsColumnSkeleton items={1} hideSource />
                ) : (
                    <ListItem>
                        <ListItemIcon>
                            <Radio
                                id="new-widget"
                                checked={payload?.selectedWidget?.id === 'newWidget'}
                                onChange={(): void =>
                                    dispatch(
                                        setPayload({
                                            key: 'selectedWidget',
                                            value: { id: 'newWidget', name: 'New Widget' },
                                        })
                                    )
                                }
                            />
                        </ListItemIcon>
                        <ListItemText>
                            <CustomTransComponent translationKey={'WIDGETS:NEW_WIDGET'} />
                        </ListItemText>
                    </ListItem>
                )}
            </List>
            <Stack py={1} pl={2}>
                <Typography
                    variant="body1"
                    fontSize={'12px'}
                    fontWeight={'600'}
                    letterSpacing={'2px'}
                    textTransform={'uppercase'}
                >
                    <CustomTransComponent translationKey={'WIDGETS:ADD_WIDGET_MODAL.EXISTING_WIDGETS'} />
                </Typography>
            </Stack>
            <Stack className="bg-white height-300 overflow-y-scroll">
                {(isLoading || isFetching) && <WidgetsColumnSkeleton items={5} />}
                {!widgets?.data?.records?.length && !(isFetching || isLoading) && (
                    <CustomEmptyState
                        icon={<InfoOutlined fontSize={'inherit'} sx={{ color: Colors.gray[200] }} />}
                        title={
                            (
                                <CustomTransComponent
                                    translationKey={'WIDGETS:ADD_WIDGET_MODAL.NO_EXISTING_WIDGETS'}
                                    replace={{
                                        label: payload?.widgetType?.label,
                                    }}
                                />
                            ) as any
                        }
                    />
                )}
                {Boolean(widgets?.data?.records?.length) && !(isFetching || isLoading) && (
                    <>
                        <Stack
                            direction={'row'}
                            alignItems={'center'}
                            justifyContent={'space-between'}
                            py={1}
                            className="border-bottom-1"
                        >
                            <Stack direction={'row'} pl={3} alignItems={'center'} className="flex-50">
                                <Typography variant="subtitle2" fontSize={'14px'} fontWeight={'600'}>
                                    <CustomTransComponent translationKey={'COMMON:NAME'} />
                                </Typography>
                                <IconButton
                                    onClick={() => {
                                        setSortPayload((prev: any) => ({
                                            ...prev,
                                            sortType: prev.sortType === 'DESC' ? 'ASC' : 'DESC',
                                        }));
                                    }}
                                >
                                    {sortPayload?.sortType === 'DESC' ? (
                                        <ArrowDownward className="height-18 width-18" />
                                    ) : (
                                        <ArrowUpward className="height-18 width-18" />
                                    )}
                                </IconButton>
                            </Stack>
                            <Stack direction={'row'} alignItems={'center'} className="flex-40">
                                <Typography variant="subtitle2" fontSize={'14px'} fontWeight={'600'}>
                                    <CustomTransComponent translationKey={'COMMON:DEVICE'} />
                                </Typography>
                                <IconButton>
                                    <ArrowDropDown className="height-18 width-18" />
                                </IconButton>
                            </Stack>
                        </Stack>

                        <Stack className="height-250 overflow-y-scroll">
                            {widgets?.data?.records?.map((record: any) => (
                                <Stack
                                    key={record?.id}
                                    py={'12px'}
                                    direction={'row'}
                                    alignItems={'center'}
                                    justifyContent={'space-between'}
                                    className="border-bottom-1"
                                >
                                    <Stack
                                        pl={2}
                                        direction={'row'}
                                        alignItems={'center'}
                                        spacing={2}
                                        className="flex-50"
                                    >
                                        <Radio
                                            checked={record?.id === payload?.selectedWidget?.id}
                                            onChange={(): void => {
                                                dispatch(setPayload({ key: 'selectedWidget', value: record }));
                                            }}
                                        />
                                        <Typography variant="body1">{record?.name ?? '--'}</Typography>
                                    </Stack>
                                    <Stack className="flex-40">
                                        <Typography variant="body2" fontSize={'14px'}>
                                            {getWidgetTypeValidationConfig(record?.widgetType?.id)?.isSourceRequired
                                                ? record?.sourceName ?? '(Linked)'
                                                : '-'}
                                        </Typography>
                                    </Stack>
                                </Stack>
                            ))}
                        </Stack>
                    </>
                )}
            </Stack>
        </Stack>
    );
};
